import {DeviceModes, ITopOffData} from "features/order/self-serve/types";
import {
  SelfServiceStatuses,
  ServiceTypes,
  MachineTypes,
  OrderableTypes,
  MachineManufacturers,
} from "../constants";

export interface FetchStoreSettingsParams {
  token: string;
}

export interface AddPromoData {
  promoCode: string;
}

export interface RemovePromoData {
  promotionId: number;
}

export interface updateTipData {
  isTipRemoved?: boolean;
  appliedTip?: any;
}

export interface AddCreditData {
  appliedCredits: number;
}

export interface SetOnlineOrderReturnMethodData {
  returnMethod: any;
}

export interface UpdatePaymentMethodIntentData {
  paymentToken: any;
  serviceOrderId?: string;
  orderToken?: string;
}

export interface CancelOrderData {
  token?: string;
  isCancelSubscription: boolean;
  serviceOrderId?: string | number;
}

export interface GetRouteDeliveryData {
  orderDelivery: string | number;
  serviceOrderId?: string;
  orderToken?: string;
}

interface PromotionOrder {
  promotionId: number;
  promotionAmount: number; // in Dollars
  promotionName: string;
}

export interface OrderEntity {
  id: number;
  promotion?: PromotionOrder;
  orderableType: OrderableTypes; // Turn
  subtotal: number; // in Dollars >> in DB it is "netOrderTotalInCents" value in "turns" table coverted to Dollars
  totalPaid: number;
}

export interface CustomerEntity {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface MachineEntity {
  id: number;
  name: string;
  prefix: string;
  pricePerTurnInCents: number;
  type: MachineTypes;
  isDumbDumb: boolean;
  deviceMode: DeviceModes | null;
  manufacturer: MachineManufacturers | null;
  qrCode: {
    hash: string;
    id: number;
    machineId: number;
  } | null;
}

export interface SelfOrderEntity {
  id: number;
  code: string;
  turnCodeWithPrefix: string;
  status: SelfServiceStatuses; // CREATED, ENABLED, STARTED, RUNNING, COMPLETED
  serviceType: ServiceTypes; // SELF_SERVICE
  createdAt: string; // formatted date
  startedAt: string; // formatted date
  completedAt: string; // formatted date
  netOrderTotalInCents: number;
  totalTurnTime: number; // for Dryers, if Washer - will be zero
  machine: MachineEntity;
  business: {
    id: number;
  };
  store: {
    id: number;
    address: string;
  };
  centsCustomer: CustomerEntity;
  order: OrderEntity;
  topOffData: ITopOffData | null;
}

export enum OrderStatuses {
  SUBMITTED = "SUBMITTED",
  DRIVER_PICKED_UP_FROM_CUSTOMER = "DRIVER_PICKED_UP_FROM_CUSTOMER",
  READY_FOR_INTAKE = "READY_FOR_INTAKE",
  READY_FOR_PROCESSING = "READY_FOR_PROCESSING",
  PROCESSING = "PROCESSING",
  READY_FOR_PICKUP = "READY_FOR_PICKUP",
  READY_FOR_DRIVER_PICKUP = "READY_FOR_DRIVER_PICKUP",
  EN_ROUTE_TO_CUSTOMER = "EN_ROUTE_TO_CUSTOMER",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
}

/** e.g. $10.00 or 10.00% */
export type TipOption = string;
